import React, { createContext, useContext, useState } from 'react'
import _ from 'lodash'

import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

const StateContext = createContext()

export const useGlobalState = () => useContext(StateContext)

export const GlobalStateProvider = ({ children }) => {
  const [showSnackbar, setShowSnackbar] = useState(false)

  const [globalState] = useState({
    showOnlyMyAMVs: false,
    sortAscendingOrder: false,
    setShowSnackbar,
    sortableAMVFields: [
      { value: 'youtubeViews', text: 'Youtube view count' },
      { value: 'additionDate', text: 'Addition Date' },
      { value: 'youtubeUploadDate', text: 'Youtube upload date' },
      { value: 'duration', text: 'Duration' }
    ],
    AMVFieldToSortBy: 'additionDate',
    sortingDirection: 'desc',

    filterableAMVFields: [
      { value: 'animeTitle', text: 'Anime' },
      { value: 'artist', text: 'Artist' },
      { value: 'song', text: 'Song' },
      { value: 'creator', text: 'Creator' }
    ],
    AMVFieldToFilterBy: 'animeTitle',

    AMVFieldValueToFilterBy: null
  })

  return (
    <StateContext.Provider value={globalState}>
      {children}
      <Snackbar open={Boolean(showSnackbar)} autoHideDuration={6000} onClose={() => setShowSnackbar(false)}>
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity={showSnackbar.error ? 'error' : 'success'}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {showSnackbar.msg}
        </Alert>
      </Snackbar>
    </StateContext.Provider>
  )
}

import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useSearchParams, useParams } from 'react-router-dom'
import EastIcon from '@mui/icons-material/East'
import { Tooltip, Input, Pagination, Autocomplete, AutocompleteItem, Progress, Link } from '@nextui-org/react'
import { Button } from '@nextui-org/button'
import cn from 'classnames'
import axios from 'axios'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga4'
import SearchIcon from '@mui/icons-material/Search'
import _ from 'lodash'
import { useMediaQuery } from 'react-responsive'
import WarningIcon from '@mui/icons-material/Warning'
import CloseIcon from '@mui/icons-material/Close'
import toast, { Toaster } from 'react-hot-toast'
import AppleIcon from '@mui/icons-material/Apple'
import { useLocalStorage } from '@rehooks/local-storage'
import { isIOS } from 'mobile-device-detect'
import { formatDistanceToNow } from 'date-fns'
import Skeleton from '@mui/material/Skeleton'

import { Comment } from '../Comment'

import ChromeIcon from './assets/chrome.svg?url'

import './styles.scss'

const extensionLink = 'https://chromewebstore.google.com/detail/sort-youtube-comments/mlmkffbkmdphkbnapkcnmhjiinhlblde?authuser=0&hl=en'

const pageSize = 20
const sortFields = [
  { label: 'Likes', value: 'likeCount' },
  { label: 'Date', value: 'publishedAt' },
  { label: 'Replies', value: 'replyCount' }
  // { label: 'Subscribers', value: 'subscriberCount' }
]

const useAnalyticsEventTracker = (category = 'Get Comments') => {
  const eventTracker = (action = 'test action', label = 'test label') => {
    ReactGA.event({ category, action, label })
  }
  return eventTracker
}

const formatDuration = (duration) => {
  const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/)
  const hours = (parseInt(match[1]) || 0) ? parseInt(match[1]) : 0
  const minutes = (parseInt(match[2]) || 0) ? parseInt(match[2]) : 0
  const seconds = (parseInt(match[3]) || 0) ? parseInt(match[3]) : 0

  const formattedHours = hours ? `${hours}:` : ''
  const formattedMinutes = minutes < 10 && hours ? `0${minutes}` : minutes
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds

  return `${formattedHours}${formattedMinutes}:${formattedSeconds}`
}

const VideoPreview = ({ video, clear }) => {
  const [channelThumbnail, setChannelThumbnail] = useState('')
  const [loadingImg, setLoadingImg] = useState(true) // Loading state

  useEffect(() => {
    const fetchChannelThumbnail = async () => {
      try {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/channels?part=snippet&id=${video.channelId}&key=AIzaSyDUo9EzSk91iyo6HUiJpPShuJNCfJMVkrw`
        )
        const channelData = response.data.items[0]
        setChannelThumbnail(channelData.snippet.thumbnails.default.url)
        setLoadingImg(false) // Stop loading after data is fetched
      } catch (error) {
        console.error('Error fetching channel thumbnail:', error)
        setLoadingImg(false) // Stop loading even if there's an error
      }
    }

    fetchChannelThumbnail()
  }, [video?.channelId])

  const channelUrl = `https://www.youtube.com/${video?.channelTitle}`
  const videoUrl = `https://www.youtube.com/watch?v=${video?.videoId}`

  const loading = loadingImg || !video

  return (
    <div className="video-preview">
      {/* Thumbnail */}
      <div className="thumbnail-container">
        {loading
          ? (
            <Skeleton variant="rectangular" width="100%" height={180} />
            )
          : (
            <a href={videoUrl}>
              <img src={video.thumbnail} alt={video.title} className="thumbnail" />
            </a>
            )
        }
        <div className="close-icon" onClick={clear}>
          ✕
        </div>
        {!loading && (
          <div className="duration">{formatDuration(video.duration)}</div>
        )}
      </div>

      {/* Video Details */}
      <div className="video-details">
        {loading
          ? (
            <Skeleton variant="circular" width={40} height={40} style={{ marginRight: 10 }} />
            )
          : (
            <a href={channelUrl}>
              <img
                src={channelThumbnail}
                alt={video.channelTitle}
                className="channel-avatar"
              />
            </a>
            )
        }
        <div className="video-info">
          {loading
            ? (
              <Skeleton variant="text" width="100%" />
              )
            : (
              <a className="video-title" title={video.title} href={videoUrl}>
                {video.title}
              </a>
              )
          }
          {loading
            ? (
              <Skeleton variant="text" width="60%" />
              )
            : (
              <div className="video-meta">
                <a href={channelUrl}>{video.channelTitle}</a> •{' '}
                {Number(video.viewCount).toLocaleString()} views •{' '}
                {formatDistanceToNow(new Date(video.publishedAt))} ago
              </div>
              )
          }
        </div>
      </div>
    </div>
  )
}

export const GetComments = ({ title: overrideTitle, description: overrideDescription, loadingFromServer }) => {
  const title = overrideTitle || 'Youtube comments search'

  const description = overrideDescription || 'Youtube comments search. By Date, by likes, by newest, by oldest, by followers/subscribers count.'

  const someStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: title,
    url: 'https://youtube-comments.io'
  }

  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const gaEventTracker = useAnalyticsEventTracker()

  const [videoUrl, setVideoUrl] = useState('')

  const [searchState, setSearchState] = useState('')
  const [repliesSearchState, setRepliesSearchState] = useState('')

  const [comments, setComments] = useState([])
  const [pageCount, setPageCount] = useState()
  const [totalComments, setTotalComments] = useState()

  const [replies, setReplies] = useState()
  const [repliesPageCount, setRepliesPageCount] = useState()
  const [repliesTotalCount, setRepliesTotalCount] = useState()
  const [specialError, setSpecialError] = useState()

  const [videoInfo, setVideoInfo] = useState(null)

  const [hideMobileAppPopup, setHideMobileAppPopup] = useLocalStorage()

  const actualLoading = loadingFromServer || (!process.env.SERVER && window.loadingFromServer)
  const [loading, setLoading] = useState(actualLoading)

  const [error, setError] = useState()

  const [progress, setProgress] = useState(0)

  const [, setLoadingMessage] = useState()

  const timerRef = useRef()
  const timerRef2 = useRef()

  const isMobile = useMediaQuery({
    query: '(max-width: 400px)'
  })

  const { videoId } = useParams()

  const searchParamsObject = Object.fromEntries(searchParams)

  const {
    page = 1,
    sortField = 'likeCount',
    sortDirection = 'desc',
    search,

    repliesPage = 1,
    repliesSortField = 'likeCount',
    repliesSortDirection = 'desc',
    repliesParentCommentId,
    repliesSearch
  } = searchParamsObject

  const updateSearchParams = value => {
    setSearchParams({ ...searchParamsObject, ...value })
  }

  const setRepliesSortField = repliesSortField => {
    updateSearchParams({ page: 1, repliesSortField })
  }

  const setSortField = sortField => {
    updateSearchParams({ page: 1, sortField })
  }

  const setRepliesPage = repliesPage => {
    updateSearchParams({ repliesPage })
  }

  const setPage = page => {
    updateSearchParams({ page })
  }

  const setRepliesSortDirection = repliesSortDirection => {
    updateSearchParams({ repliesPage: 1, repliesSortDirection })
  }

  const setSortDirection = sortDirection => {
    updateSearchParams({ page: 1, sortDirection })
  }

  const setSearch = search => {
    if (search) {
      updateSearchParams({ page: 1, search })
    } else {
      setSearchParams({ ..._.omit(searchParamsObject, 'search'), page: 1 })
    }
  }

  const setRepliesSearch = repliesSearch => {
    updateSearchParams({ page: 1, repliesSearch })
  }

  const setRepliesParentComment = repliesParentCommentId => {
    updateSearchParams({ repliesParentCommentId })
  }

  useEffect(() => {
    if (isIOS && !hideMobileAppPopup) {
      const toastId = toast(<div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 14,
        alignItems: 'center'
      }}>
        <p style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Link onClick={(e) => { e.preventDefault(); setHideMobileAppPopup(true); toast.dismiss(toastId) }}>Don't show again</Link>
          <CloseIcon onClick={() => toast.dismiss(toastId)} />
        </p>
        <AppleIcon sx={{ fontSize: 40 }} />
        <p>We have mobile app for IOS now!</p>
        <Link href="https://apps.apple.com/ua/app/yt-comments-search/id6636483465" underline="always">Download Now</Link>
      </div>, { duration: Infinity })
    } else {
    }
  }, [])

  useEffect(() => {
    if (videoId && !videoUrl) {
      const url = `https://www.youtube.com/watch?v=${videoId}`

      setVideoUrl(url)
    }
  }, [videoId])

  useEffect(() => {
    if (videoId) {
      fetchComments()
    }
  }, [page, sortField, sortDirection, search, videoId])

  useEffect(() => {
    if (repliesParentCommentId) {
      fetchReplies()
    }
  }, [repliesPage, repliesSortField, repliesSortDirection, repliesSearch, repliesParentCommentId])

  useEffect(() => {
    if (repliesSearch !== repliesSearchState) {
      setRepliesSearchState(repliesSearch)
    }

    if (search !== searchState) {
      setSearchState(search)
    }
  }, [search, repliesSearch])

  useEffect(() => {
    const fetchVideoInfo = async () => {
      try {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=AIzaSyDUo9EzSk91iyo6HUiJpPShuJNCfJMVkrw&part=snippet,contentDetails,statistics`
        )
        const info = {
          thumbnail: response.data.items[0].snippet.thumbnails.maxres.url,
          title: response.data.items[0].snippet.title,
          publishedAt: response.data.items[0].snippet.publishedAt,
          duration: response.data.items[0].contentDetails.duration,
          channelTitle: response.data.items[0].snippet.channelTitle,
          channelId: response.data.items[0].snippet.channelId,
          likeCount: response.data.items[0].statistics.likeCount,
          viewCount: response.data.items[0].statistics.viewCount,
          videoId
        }

        setVideoInfo(info)
      } catch (err) {
        setError('Failed to fetch video information')
      }
    }

    if (videoId) {
      fetchVideoInfo()
    }
  }, [videoId])

  const fetchComments = async () => {
    setLoading(true)

    try {
      fetchLoadingPercentage(videoId)
      const { data: { comments, pageCount, totalCount }, error } = await axios.post('/api/comments', {
        page,
        pageSize,
        sortField,
        sortDirection,
        videoId,
        search
      })
      console.log('error----------------: ', error)

      setComments(comments)
      setPageCount(pageCount)
      setTotalComments(totalCount)

      setLoading(false)
    } catch (err) {
      setComments([])
      setTotalComments()

      navigate('/')

      console.log(err)
      if (err.response.data.message.includes('daily limit')) {
        setSpecialError(err.response.data.message)
        setLoading(false)
        return
      }

      setError(err.response.data.message)

      setLoading(false)

      console.log('error: ', err)
    }
  }

  const fetchReplies = async () => {
    setLoading(true)

    const { data: { replies, pageCount, totalCount } } = await axios
      .post('/api/replies', {
        page: repliesPage,
        pageSize,
        sortField: repliesSortField,
        sortDirection: repliesSortDirection,
        search: repliesSearch,
        commentId: repliesParentCommentId
      })

    setReplies(replies)
    setRepliesPageCount(pageCount)
    setRepliesTotalCount(totalCount)

    setLoading(false)
  }

  const fetchLoadingPercentage = async () => {
    try {
      const { data: { progress } } = await axios
        .post('/api/comments/progress', { videoId })

      if (!timerRef.current) {
        timerRef.current = setInterval(() => fetchLoadingPercentage(), 1000)
        timerRef2.current = setTimeout(() => {
          if (loading) {
            setLoadingMessage('Loading comments from a video with a lot of them is easier with our extension!')
          }
          clearTimeout(timerRef2.current)
          timerRef2.current = null
        }, 6000)
      } else {
        setProgress(Math.round(progress))

        if (progress === 100) {
          clearInterval(timerRef.current)
          setProgress(100)
          setTimeout(() => {
            setProgress(0)
          }, 4000)
          timerRef.current = null
        }
      }
    } catch (err) {
      if (timerRef.current) {
        clearInterval(timerRef.current)
        setProgress(0)
        timerRef.current = null
      }
    }
  }

  const clear = () => {
    setVideoUrl('')
    setComments([])
    setPageCount()
    setTotalComments()
    setSearchState('')
    navigate({ pathname: '/', search: '' })
    setReplies()
    setRepliesSearchState('')
    setSpecialError()
  }

  const clearReplies = () => {
    setReplies()
    setRepliesSearchState('')
    setSearchParams({
      ..._.omit(searchParamsObject, ['repliesPage', 'repliesSortField', 'repliesSortDirection', 'repliesParentCommentId', 'repliesSearch'])
    })
  }

  const tooltipText = (replies ? repliesSortField === 'publishedAt' : sortField === 'publishedAt')
    ? (replies ? repliesSortDirection : sortDirection) === 'asc' ? 'Oldest first (click to reverse)' : 'Newest first (click to reverse)'
    : (replies ? repliesSortDirection : sortDirection) === 'asc' ? 'Smallest first (click to reverse)' : 'Biggest first (click to reverse)'
  return (
    <>
      <Helmet encodeSpecialCharacters={false}>
        <title>{title}</title>
        <meta name="description" content={description} />
        <script type="application/ld+json">{JSON.stringify(someStructuredData)}</script>
      </Helmet>
      <Toaster />
      {(comments.length === 0 && !videoId)
        ? (
          <div className="input-container">
            <Input
              isClearable
              onClear={clear}
              radius='lg'
              size='lg'
              type="text"
              variant='flat'
              labelPlacement='outside-left'
              placeholder='Paste youtube video URL here'
              onChange={e => {
                setVideoUrl(e.target.value)

                if (error) {
                  setError()
                }
              }}
              value={videoUrl}
              fullWidth={false}
              className='w-auto get-commponents-input'
              classNames={{
                base: '!w-full max-550',
                mainWrapper: 'w-full',
                inputWrapper: 'w-full',
                input: '!text-[#52525b]'
              }}
              errorMessage={error}
              isInvalid={Boolean(error)}
            />
            <Button
              isDisabled={loading}
              size='lg'
              radius='lg'
              className='button'
              style={{ fontWeight: 500, minWidth: 186 }}
              color='primary'
              onClick={() => {
                setLoading(true)
                gaEventTracker('get comments clicked', videoUrl)

                // mobile youtube url format check
                if (!videoUrl.includes('youtu.be')) {
                  const urlParams = new URLSearchParams(videoUrl.split('?')[1])
                  const videoId = urlParams.get('v')
                  if (!videoId) {
                    setError('Invalid Video URL')
                  }

                  navigate(`/${videoId}`)
                }
                if (videoUrl.includes('youtu.be')) {
                  const arr = videoUrl.split('/')
                  const videoId = arr[arr.length - 1].split('?')[0]

                  navigate(`/${videoId}`)
                }
              }}
              endContent={<EastIcon fontSize='24px' />}
            >
              Get Comments
            </Button>
            {!isMobile && <Button
              style={{ width: 164, minWidth: 164 }}
              href={extensionLink}
              as={Link}
              color="primary"
              variant="flat"
              className='extension'
              target='_blank'
              size='lg'
              radius='lg'
            >
              <span>Get extension</span>
              <img src={ChromeIcon} alt="chrome extension icon" />
            </Button>}
          </div>
          )
        : <VideoPreview video={videoInfo} clear={clear} />
      }
      {loading && (
        <div className='progress-bar-container'>
          <span>{parseInt(progress)}%</span>
          <Progress
            aria-label="Loading..."
            value={parseInt(progress)}
            className="max-w-md"
          />
        </div>
      )}
      {(!loading && (videoId || replies)) && (
        <div className='total'>
          <div className='total-with-arrow'>
            {replies && <ArrowBackIcon onClick={() => clearReplies()} />}
            <div>
              <b style={{ fontWeight: 400 }}>{`Total number of ${replies ? 'replies' : 'comments'}: `}</b>
              <small>{replies ? repliesTotalCount : totalComments}</small>
            </div>
          </div>
          <div className='search-and-sort'>
            <div style={{ display: 'flex', gap: 8 }}>
              <Input
                size='lg'
                radius='sm'
                type="search"
                placeholder="Search in comments"
                onChange={e => replies ? setRepliesSearchState(e.target.value) : setSearchState(e.target.value)}
                value={(replies ? repliesSearchState : searchState) || ''}
                onClear={() => {
                  if (replies) {
                    setRepliesSearch('')
                    setRepliesSearchState('')
                  } else {
                    setSearch('')
                    setSearchState('')
                  }
                }}
                classNames={{
                  base: 'w-300'
                }}
              />
              <Button
                isDisabled={loading}
                size='lg'
                radius='sm'
                className='button button-search'
                style={{ fontWeight: 500, minWidth: 126 }}
                color='primary'
                variant="flat"
                onClick={() => {
                  setLoading(true)
                  if (replies) {
                    gaEventTracker('search replies clicked')
                    updateSearchParams({ repliesPage: 1, repliesSearch: repliesSearchState })
                  } else {
                    gaEventTracker('search comments clicked')
                    updateSearchParams({ page: 1, search: searchState })
                  }
                }}
                endContent={<SearchIcon fontSize='24px' />}
              >
                Search
              </Button>
            </div>
            <div className="flex md:flex-nowrap gap-4 items-center">
              <Tooltip
                content={tooltipText}
              >
                <div
                  className={cn('sort-direction', { asc: (replies ? repliesSortDirection : sortDirection) === 'asc' })}
                  onClick={() => {
                    if (replies) {
                      const newRepliesSortDirection = repliesSortDirection === 'asc' ? 'desc' : 'asc'
                      setRepliesSortDirection(newRepliesSortDirection)
                    } else {
                      const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
                      setSortDirection(newSortDirection)
                    }
                  }}
                >
                  <div />
                  <div />
                  <div />
                </div>
              </Tooltip>
              <Autocomplete
                label="Sort By"
                className="max-w-xs cursor-pointer-child"
                isReadOnly
                size='sm'
                selectedKey={replies ? repliesSortField : sortField}
                onSelectionChange={replies ? setRepliesSortField : setSortField}
                isClearable={false}
              >
                {sortFields.map(field => (
                  <AutocompleteItem key={field.value} value={field.value}>
                    {field.label}
                  </AutocompleteItem>
                ))}
              </Autocomplete>
            </div>
          </div>
        </div>
      )}
      {(!loading && !videoUrl && !videoId) && ((replies && repliesTotalCount === 0) || (comments.length === 0 && videoId))
        ? (
          <b
            style={{
              textAlign: 'center',
              marginBottom: 40,
              fontSize: 32,
              fontWeight: 500,
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: 80
            }}
          >
            No {replies ? 'Replies' : 'Comments'}{replies ? '(maybe comment was deleted)' : ''}
          </b>
          )
        : (!loading && (
          <ul className='list'>
            {_.uniqBy(replies || comments, i => i.text).map(i => (
              <Comment key={i.id} {...i} search={replies ? repliesSearch : search} replies={replies} fetchReplies={() => setRepliesParentComment(i.id)} />
            ))}
          </ul>
          ))
      }
      {(!loading && (replies ? repliesPageCount && repliesPageCount > 1 : pageCount && pageCount > 1))
        ? <Pagination
          className='my-pagination'
          style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingBottom: 32 }}
          showControls={!isMobile}
          total={replies ? repliesPageCount : pageCount}
          page={parseInt(replies ? repliesPage : page)}
          onChange={replies ? setRepliesPage : setPage}
        />
        : null
      }
      {specialError && (
        <p className='warning' style={{
          display: 'flex',
          justifyContent: 'center',
          fontSize: '40px',
          padding: 80,
          alignItems: 'center',
          gap: 30,
          flexDirection: 'column',
          textAlign: 'center',
          color: '#5f5f5f'
        }}><WarningIcon sx={{ fill: '#ff9966', fontSize: 80 }} /><span>{specialError}</span></p>
      )}
      {(!totalComments && !replies && !loading) && (
        <footer style={{ position: 'absolute', bottom: 14, left: '50%', transform: 'translateX(-50%)' }}><a href='/support'>support</a></footer>
      )}
    </>
  )
}

/* eslint-disable react/no-danger-with-children */
import React from 'react'
import { Card, CardHeader, CardBody, CardFooter } from '@nextui-org/react'
import { formatDistanceToNow, format } from 'date-fns'
import { Button } from '@nextui-org/button'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import ThumbUpIcon from '@mui/icons-material/ThumbUp'

import './styles.scss'

const timecodeToSeconds = (timecode) => {
  const parts = timecode.split(':').map(Number)

  // If the timecode is in HH:MM:SS format
  if (parts.length === 3) {
    const [hours, minutes, seconds] = parts
    return hours * 3600 + minutes * 60 + seconds
  }

  // If the timecode is in MM:SS format
  if (parts.length === 2) {
    const [minutes, seconds] = parts
    return minutes * 60 + seconds
  }

  // If the timecode is just seconds (SS)
  if (parts.length === 1) {
    return parts[0]
  }

  // If the format is incorrect, return null or handle the error
  return null
}

const timecodeRegex = /\b(\d{1,2}):([0-5][0-9])(?::([0-5][0-9]))?\b/g

export const Comment = ({ shadow, text, authorDisplayName, authorChannelUrl, id, videoId, likeCount, authorProfileImageUrl, publishedAt, replyCount, replies, fetchReplies, search }) => {
  const replyTo = text.match(/@@[\w\W]+?\s/)

  let htmlOutput = text
  if (replyTo) {
    htmlOutput = htmlOutput.replaceAll(
      replyTo,
      `<a target='_blank' href="https://www.youtube.com/${replyTo[0].replace('@', '')}" class="reply-to-text">${replyTo[0].replace('@', '')}</a>`
    )
  }

  if (search) {
    htmlOutput = htmlOutput.replace(new RegExp(search, 'gi'), `<b>${search}</b>`)
  }
  const timecodeFound = text.match(timecodeRegex)

  if (timecodeFound) {
    htmlOutput = htmlOutput.replaceAll(timecodeRegex, i => {
      const timeInSeconds = timecodeToSeconds(i)
      return `<a target='_blank' href="https://www.youtube.com/watch?v=${videoId}&t=${timeInSeconds}s" class="reply-to-text">${i}</a>`
    })
  }

  const highlightCommentUrl = `https://www.youtube.com/watch?v=${videoId}&lc=${id}`
  return (
    <Card shadow={shadow}>
      <CardHeader className="justify-between">
        <div className="flex gap-3">
          <a href={authorChannelUrl}>
            <LazyLoadImage
              src={authorProfileImageUrl} alt="profile image"
              height={55}
              width={55}
              style={{
                borderRadius: '50%',
                height: 55,
                width: 55,
                border: '2px solid #e5e7eb',
                padding: '2px'
              }}
              effect="blur"
            />
          </a>
          <div className="flex flex-col gap-1 items-start justify-center">
            <h4 className="text-small font-semibold leading-none text-default-600">{authorDisplayName}</h4>
            <h5 className="text-small tracking-tight text-default-400">
              {formatDistanceToNow(new Date(publishedAt))} ago ({format(new Date(publishedAt), 'PPPPp')})
            </h5>
          </div>
        </div>
        <Button
          className={'bg-transparent text-foreground border-default-200 desktop-open'}
          color="primary"
          radius="full"
          size="sm"
          variant={'bordered'}
          onPress={() => window.open(highlightCommentUrl, '_blank').focus()}
        >
          Open
        </Button>
      </CardHeader>
      <CardBody className="px-3 py-0 text-small text-default-400">
        <pre dangerouslySetInnerHTML={{ __html: htmlOutput }} />
      </CardBody>
      <CardFooter className="gap-3">
        <div className="flex gap-1">
          <div className="font-semibold text-default-400 text-small"><a href={highlightCommentUrl} target='_blank' rel="noreferrer">
            <ThumbUpIcon /></a> <span>{likeCount}</span>
          </div>
        </div>
        {!replies && (
          <div style={replyCount > 0 ? { cursor: 'pointer' } : { cursor: 'auto' }} className="flex gap-1 cursor-pointer" onClick={() => replyCount > 0 ? fetchReplies() : null}>
            <p className="font-semibold text-default-400 text-small">{replyCount}</p>
            <p className="text-default-400 text-small">Replies</p>
          </div>
        )}
        <Button
          className={'bg-transparent text-foreground border-default-200 mobile-open'}
          color="primary"
          radius="full"
          size="sm"
          variant={'bordered'}
          onPress={() => window.open(highlightCommentUrl, '_blank').focus()}
        >
          Open
        </Button>
      </CardFooter>
    </Card>
  )
}
